<template>
    <ValidationObserver tag="form" class="billing" ref="observer" @submit.prevent="onSubmit">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide('PayerAdd')"> ×
        </button>
        <div class="billing__header">
            <h3 class="h3 billing__title">{{isEdit ? `Редактировать` : `Добавить нового`}} плательщика</h3>
        </div>
        <div class="billing__body">
            <div class="billing__orgform">
                <h3 class="h3">Форма организации</h3>
                <div class="form-check">
                    <input
                        :disabled="isProjectAdmin"
                        class="form-check-input" type="radio" name="exampleRadios" id="id_physical"
                           v-model="form.type" value="physical" @change="resetForm" checked>
                    <label class="form-check-label" for="id_physical">
                        Физическое лицо
                    </label>
                </div>
                <div class="form-check">
                    <input
                        :disabled="isProjectAdmin"
                        class="form-check-input" type="radio" name="exampleRadios" id="id_individual"
                           v-model="form.type" @change="resetForm" value="individual">
                    <label class="form-check-label" for="id_individual">
                        Индивидуальный предприниматель
                    </label>
                </div>
                <div class="form-check disabled">
                    <input
                        :disabled="isProjectAdmin"
                        class="form-check-input" type="radio" name="exampleRadios" id="id_law" v-model="form.type"
                           @change="resetForm" value="law">
                    <label class="form-check-label" for="id_law">
                        Юридическое лицо
                    </label>
                </div>
            </div>

            <div class="billing__form">
                <!--Форма для Физическое лицо-->
                <div v-if="form.type === 'physical'" class="billing__form-item" key="physical">
                    <h3 class="h3">Информация о плательщике</h3>
                    <div class="form-row mt-30">
                        <!--<ValidationProvider tag="div" vid="new_password" v-slot="{errors}" :rules="`required`" class="form-check">-->
                        <ValidationProvider tag="div" vid="id_physical_lastname" v-slot="{errors}" :rules="`required`"
                                            class="form-group col-md-6">
                            <label class="billing__label" for="id_physical_lastname">Фамилия <span
                                class="billing__required">*</span></label>
                            <input v-model="form.last_name" type="text" class="form-control" id="id_physical_lastname">
                            <span class="v-error animated fadeInDown" v-if="errors.length">
                                {{errors[0]}}
                            </span>
                        </ValidationProvider>
                        <ValidationProvider tag="div" vid="id_physical_firstname" v-slot="{errors}" :rules="`required`"
                                            class="form-group col-md-6">
                            <label class="billing__label" for="id_physical_firstname">Имя <span
                                class="billing__required">*</span></label>
                            <input v-model="form.first_name" type="text" class="form-control"
                                   id="id_physical_firstname">
                            <span class="v-error animated fadeInDown" v-if="errors.length">
                                {{errors[0]}}
                            </span>
                        </ValidationProvider>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label class="billing__label" for="id_physical_middle">Отчество</label>
                            <input v-model="form.middle_name" type="text" class="form-control" id="id_physical_middle">
                        </div>
                    </div>
                </div>
                <!--Форма для Индивидуальный предприниматель-->
                <div v-else-if="form.type === 'individual'" class="billing__form-item" key="individual">
                    <div class="billing__wrap">
                        <h3 class="h3">Информация о плательщике</h3>
                        <div class="form-row mt-30">
                            <ValidationProvider tag="div" vid="id_individual_name" v-slot="{errors}" :rules="`required`"
                                                class="form-group col-md-6">
                                <label class="billing__label" for="id_individual_name">Наименование <span
                                    class="billing__required">*</span></label>
                                <input v-model="form.name" type="text" class="form-control" id="id_individual_name">
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider tag="div" vid="id_individual_law_address" v-slot="{errors}"
                                                :rules="`required`" class="form-group col-md-6">
                                <label class="billing__label" for="id_individual_law_address">Юридический адрес <span
                                    class="billing__required">*</span></label>
                                <input v-model="form.legal_address" type="text" class="form-control"
                                       id="id_individual_law_address">
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                        </div>
                        <div class="form-row">
                            <ValidationProvider tag="div" vid="id_individual_tin" v-slot="{errors}" :rules="`required|min_length:10|max_length:12`"
                                                class="form-group col-md-6">
                                <label class="billing__label" for="id_individual_tin">ИНН <span
                                    class="billing__required">*</span></label>
                                <input
                                    v-model="form.inn"
                                    type="text"
                                    class="form-control"
                                    id="id_individual_tin"
                                    v-mask="maskForNInt"
                                    maxlength="12"
                                >
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider tag="div" vid="id_individual_ogrnip" v-slot="{errors}"
                                                :rules="`required|min_length:15`" class="form-group col-md-6">
                                <label class="billing__label" for="id_individual_ogrnip">ОГРНИП <span
                                    class="billing__required">*</span></label>
                                <input
                                    v-model="form.ogrnip"
                                    type="text"
                                    class="form-control"
                                    id="id_individual_ogrnip"
                                    v-mask="maskForNInt"
                                    maxlength="15"
                                >
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="billing__wrap mt-30">
                        <h3 class="h3">Адрес для доставки документов</h3>
                        <div class="form-row mt-30">
                            <ValidationProvider tag="div" vid="id_individual_actual_address" v-slot="{errors}"
                                                :rules="`required`" class="form-group d-flex flex-column col-12">
                                <label class="billing__label" for="id_individual_actual_address">Фактический адрес <span
                                    class="billing__required">*</span></label>
                                <input v-model="form.actual_address" type="text" class="form-control"
                                       id="id_individual_actual_address">
                                <label class="billing__label">Например, 540040 Москва, Шаболовка, дом 37, офис
                                    201</label>
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="billing__label" for="id_individual_contact">Контактное лицо</label>
                                <input v-model="form.contact_person" type="text" class="form-control"
                                       id="id_individual_contact">
                            </div>
                            <div class="form-group col-md-6">
                                <label class="billing__label" for="id_individual_phone">Телефон</label>
                                <input v-model="form.phone" type="text" class="form-control" id="id_individual_phone">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label class="billing__label" for="id_individual_note">Примечание</label>
                                <input v-model="form.note" type="text" class="form-control" id="id_individual_note">
                            </div>
                        </div>
                        <div class="form-row" v-if="isEdit && isFromAdmin">
                            <div class="form-group col-md-6">
                                <label class="billing__label" for="id_individual_contract_number">Номер договора</label>
                                <input v-model="form.contract_number" type="text" class="form-control"
                                       id="id_individual_contract_number">
                            </div>
                            <div class="form-group col-md-6">
                                <label class="billing__label" >Дата заключения договора</label>
                                <VueDatePicker
                                    class="form-control"
                                    color="#8e8e8e"
                                    :validate="true"
                                    :noHeader="true"
                                    :noCalendarIcon="true"
                                    :locale="{lang: 'ru'}"
                                    :format="'DD MMM YYYY'"
                                    placeholder=""
                                    :clearable="true"
                                    v-model="form.date" />
                            </div>
                        </div>
                    </div>
                </div>
                <!--Форма для Юридическое лицо-->
                <div v-else-if="form.type === 'law'" class="billing__form-item" key="law">
                    <div class="billing__wrap">
                        <h3 class="h3">Информация о плательщике</h3>
                        <div class="form-row mt-30">
                            <ValidationProvider tag="div" vid="id_law_name" v-slot="{errors}" :rules="`required`"
                                                class="form-group col-md-6">
                                <label class="billing__label" for="id_law_name">Наименование <span
                                    class="billing__required">*</span></label>
                                <input v-model="form.name" type="text" class="form-control" id="id_law_name">
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider tag="div" vid="id_law_law_address" v-slot="{errors}" :rules="`required`"
                                                class="form-group col-md-6">
                                <label class="billing__label" for="id_law_law_address">Юридический адрес <span
                                    class="billing__required">*</span></label>
                                <input v-model="form.legal_address" type="text" class="form-control"
                                       id="id_law_law_address">
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                        </div>
                        <div class="form-row">
                            <ValidationProvider tag="div" vid="id_law_tin" v-slot="{errors}" :rules="`required|min_length:10|max_length:12`"
                                                class="form-group col-md-6">
                                <label class="billing__label" for="id_law_tin">ИНН <span
                                    class="billing__required">*</span></label>
                                <input
                                    v-model="form.inn"
                                    type="text" class="form-control"
                                    id="id_law_tin"
                                    v-mask="maskForNInt"
                                    maxlength="12"
                                >
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider tag="div" vid="id_law_kpp" v-slot="{errors}" :rules="`required|min_length:9`"
                                                class="form-group col-md-6">
                                <label class="billing__label" for="id_law_kpp">КПП <span
                                    class="billing__required">*</span></label>
                                <input
                                    v-model="form.kpp"
                                    type="text"
                                    class="form-control"
                                    id="id_law_kpp"
                                    v-mask="maskForNInt"
                                    maxlength="9"
                                >
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                        </div>
                        <div class="form-row">
                            <ValidationProvider tag="div" vid="id_law_ogrn" v-slot="{errors}" :rules="`required|min_length:13`"
                                                class="form-group col-md-6">
                                <label class="billing__label" for="id_law_ogrn">ОГРН <span
                                    class="billing__required">*</span></label>
                                <input
                                    v-model="form.ogrn"
                                    type="text"
                                    class="form-control"
                                    id="id_law_ogrn"
                                    v-mask="maskForNInt"
                                    maxlength="13"
                                >
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="billing__wrap mt-30">
                        <h3 class="h3">Адрес для доставки документов</h3>
                        <div class="form-row mt-30">
                            <ValidationProvider tag="div" vid="id_law_actual_address" v-slot="{errors}"
                                                :rules="`required`" class="form-group d-flex flex-column col-12">
                                <label class="billing__label" for="id_law_actual_address">Фактический адрес <span
                                    class="billing__required">*</span></label>
                                <input v-model="form.actual_address" type="text" class="form-control"
                                       id="id_law_actual_address">
                                <label class="billing__label">Например, 540040 Москва, Шаболовка, дом 37, офис
                                    201</label>
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="billing__label" for="id_law_contact">Контактное лицо</label>
                                <input v-model="form.contact_person" type="text" class="form-control"
                                       id="id_law_contact">
                            </div>
                            <div class="form-group col-md-6">
                                <label class="billing__label" for="id_law_phone">Телефон</label>
                                <input v-model="form.phone" type="text" class="form-control" id="id_law_phone">
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label class="billing__label" for="id_law_note">Примечание</label>
                                <input v-model="form.note" type="text" class="form-control" id="id_law_note">
                            </div>
                        </div>
                        <div class="form-row" v-if="isEdit && isFromAdmin">
                            <div class="form-group col-md-6">
                                <label class="billing__label" for="id_law_contract_number">Номер договора</label>
                                <input v-model="form.contract_number" type="text" class="form-control"
                                       id="id_law_contract_number">
                            </div>
                            <div class="form-group col-md-6">
                                <label class="billing__label">Дата заключения договора</label>
                                <VueDatePicker
                                    class="form-control"
                                    color="#8e8e8e"
                                    :validate="true"
                                    :noHeader="true"
                                    :noCalendarIcon="true"
                                    :locale="{lang: 'ru'}"
                                    :format="'DD MMM YYYY'"
                                    placeholder=""
                                    :clearable="true"
                                    v-model="form.date" />
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Соглашение -->
                <div v-if="isProjectAdmin" class="billing__form-item">
                    <div class="billing__wrap">
                        <h3 class="h3">Договор</h3>
                        <div class="form-row mt-30">
                            <ValidationProvider tag="div" vid="number" v-slot="{errors}" class="form-group col-md-6">
                                <label class="billing__label" for="id_number">Номер </label>
                                <input v-model="agreement.number" type="text" class="form-control" id="id_number">
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                            <ValidationProvider tag="div" vid="date" v-slot="{errors}" :rules="agreement.number ? 'required' : null"
                                                class="form-group col-md-6">
                                <label class="billing__label" for="id_date">Дата <span v-show="agreement.number"
                                    class="billing__required">*</span></label>
                                <VueDatePicker
                                    class="form-control"
                                    color="#8e8e8e"
                                    :validate="true"
                                    :noHeader="true"
                                    :noCalendarIcon="true"
                                    :locale="{lang: 'ru'}"
                                    :format="'DD MMM YYYY'"
                                    placeholder=""
                                    :clearable="true"
                                    v-model="agreement.date" />
                                <span class="v-error animated fadeInDown" v-if="errors.length">
                                    {{errors[0]}}
                                </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>

            </div>

            <ValidationProvider tag="div" vid="id_accept" rules="accepted" v-slot="{errors}" class="billing__accept" v-if="!isProjectAdmin">
                <input
                    class="form-check-input position-static" type="checkbox" v-model="isAccepted" id="id_accept"/>
                <label class="form-check-label" for="id_accept">Я принимаю условия <a
                    href="https://wikiworks.io/ru/agreement/" target="_blank">договора-оферты <i
                    class="billing__external-icon"></i></a></label>
                <span class="v-error animated fadeInDown w-100" v-if="errors.length">
                    {{errors[0]}}
                </span>
            </ValidationProvider>
        </div>
        <div class="form-check billing__footer">
            <button type="submit" class="v-btn" :disabled="this.isSending">Сохранить</button>
            <button type="button" class="v-btn v-btn--transparent" @click="$modal.hide('PayerAdd')">Отменить</button>
        </div>
    </ValidationObserver>
</template>

<script>
    import session from '@/api/session';

    export default {
        name: "PayerAdd",
        components: {
            Checkbox : () => import('@/components/Checkbox')
        },
        props: {
            isProjectAdmin: {
                type: Boolean,
                default: false,
                required: false
            },
            updatePayers: {
                type: Function,
                required: false
            },
            payerData: {
                type: Object,
                required: false
            },
            isFromAdmin: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                isAccepted: false,
                form: {
                    type: 'physical',
                },
                agreement: {
                    number: '',
                    date: null,
                },
                maskForNInt: {regex: '^([0-9]*[.])?[0-9]+$', greedy: false},
                isEdit: !!this.payerData,
                payer: this.payerData,
                isSending: false
            }
        },
        methods: {
            async onSubmit() {
                this.isSending = true;
                try {
                    let isValid = await this.$refs.observer.validate();

                    if (isValid) {
                        if (!this.agreement.number) {
                            this.agreement.date = null;
                        }
                        if (this.isEdit && this.isProjectAdmin && this.form.payer_agreement) {
                            this.agreement.payer = this.form.id;
                            let response = await session.put(`/api/v1/admin/payer-agreement/${this.form.payer_agreement}/`, this.agreement);
                        } else if (this.isEdit && this.isProjectAdmin && !this.form.payer_agreement) {
                            this.agreement.payer = this.form.id;
                            let response = await session.post(`/api/v1/admin/payer-agreement/`, this.agreement);
                        }

                        let response;

                        if (this.isEdit) {
                            response = await session.put(`/api/v1/payer/${this.payer.id}/`, this.form);
                        } else {
                            response = await session.post(`/api/v1/payer/`, this.form);
                        }
                        if (this.updatePayers) {
                            this.updatePayers(response.data);
                        }
                        this.$modal.hide('PayerAdd');
                    }
                } catch (error) {
                    console.error(error)
                }
                this.isSending = false;
            },
            resetForm() {
                this.form = {
                    type: this.form.type,
                    last_name: '',
                    first_name: '',
                    middle_name: '',
                    ogrnip: null,
                    kpp: null,
                    ogrn: null,
                    name: null,
                    legal_address: null,
                    inn: null,
                    actual_address: null,
                    contact_person: '',
                    phone: '',
                    note: '',
                    contract_number: null,
                    date: null
                }
            },
        },
        async created() {
            if (this.isEdit) {
                this.form = this.payer;
            } else {
                this.resetForm();
            }
            if (this.isEdit && this.isProjectAdmin && this.form.payer_agreement) {
                let response = await session.get(`/api/v1/admin/payer-agreement/${this.form.payer_agreement}/`);
                this.agreement = response.data;
            }
        },
    }
</script>

<style lang="scss">
    @import "../billing";

    .v--modal-overlay[data-modal="PayerAdd"] {
        .v--modal-box {
            max-width: 1000px !important;
            margin: 0 auto;
        }
    }
</style>
